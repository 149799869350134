









































































































































































































@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://use.fontawesome.com/releases/v5.6.3/css/all.css");

*,
.v-application,
.v-application * {
  font-family: "Lato", serif !important;
}

h2 {
  font-weight: bold;
  font-size: 30px;
}

.v-application--wrap {
  background: url("/bg.svg");
  background-size: cover;
  background-position: left;
}

.v-responsive__content {
  display: flex;
  width: 100%;
  flex-flow: column;
  justify-content: center;

  header {
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 30%;

    h1 {
      font-weight: 800;
      text-align: center;
      font-size: 46px;
      line-height: 52px;
      letter-spacing: 0.03em;

      b {
        color: #57be8e;
        font-size: 50px;
      }
    }

    .logo {
      width: 77.77%;
      max-width: 700px;
    }

    .finger-print {
      position: absolute;
      top: 22vh;
      width: 140px;
      height: 248.89px;
    }
  }

  main {
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-grow: 1;
    height: 70%;

    p {
      font-size: 20px;
      width: 77.77%;
      text-align: center;
    }
  }
}

.v-carousel__controls {
  bottom: min(5vh) !important;
  width: 55vw !important;
  position: fixed !important;
  left: calc(50% - (55vw / 2)) !important;
  height: 5vw !important;

  .v-item-group {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      height: 4.5vw !important;
      width: 4.5vw !important;
      background: #1272b8;

      * {
        display: none;
      }

      &.v-btn--active {
        height: 5vw !important;
        width: 5vw !important;
        background: #57be8e;
      }
    }
  }
}

.v-window__next,
.v-window__prev {
  display: none !important;
}

.journey {
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  h1 {
    line-height: 33px;
    b {
      color: #57be8e;
    }
    i {
      font-style: normal;
      line-height: 40px;
      color: #1272b8;
    }
  }
  .v-btn {
    width: 230px;
    height: 39px;
    border-radius: 80px;
    background: #57be8e;
  }
  .spacer {
    height: 20vh;
    width: 100%;
  }
}
.v-input--selection-controls__ripple,
.v-icon {
  font-family: "Font Awesome 5 Free" !important;
}
